

































































import { Component, Vue, Prop } from 'vue-property-decorator';
import MButton from '@/shared/components/MButton.vue';
import { MyTeamUser } from '@/types/myteam';

@Component({
  components: {
    MButton,
    ModalExcluir: () => import(/* webpackChunkName: "modalExcluir" */ './ModalExcluir.vue'),
  },
})
export default class MyTeamSidebar extends Vue {
  @Prop({ required: true }) user: MyTeamUser;

  showModalExcluir: boolean = false;

  openModalExcluir() {
    this.showModalExcluir = true;
  }

  closeModalExcluir(event?: any) {
    this.showModalExcluir = false;
    event?.reloadUsers && this.$emit('reloadUsers')
  }
}
